class User {
  constructor() {
    if (!window.eHelpUser) window.eHelpUser = this;
    this.data = {
      id: null,
      ratings: {},
      history: [],
      searchs: [],
      accessibility: {
        contrast: false,
      },
    };
    this.load();
    document.removeEventListener("UserUpdated", this.userUpdatedHandler);
    document.addEventListener("UserUpdated", this.userUpdatedHandler);
    if (QelpCareHUB?.selected?.usecase) {
      try {
        this.historyPush(QelpCareHUB.selected.usecase);
      } catch (error) {
        console.log(error);
      }
    }
  }
  userUpdatedHandler(e) {
    window.eHelpUser.load();
  }
  historyPush(data) {
    // console.log('historyPushed');
    let history = {
      date: new Date(),
      uri: window.location.toString(),
      topicPath: data.topicPath,
      manufacturer: data.manufacturer?.path,
      category: data.category?.path,
      device: data.device?.path,
    };
    if (history.uri.match(/\?clear_cache\=true|false/)) return;

    this.data.history = this.data.history.filter((item) => {
      return item.uri != history.uri;
    });
    this.data.history.unshift(history);

    while (this.data.history.length > 20) {
      this.data.history.pop();
    }
    this.update();
    let event = new Event("UserHistoryPushed");
    document.dispatchEvent(event);
  }
  load() {
    try {
      let user = window.localStorage.getItem("qelp_latam_dynamic_user");
      if (!user) {
        user = {
          id: sha256(window.navigator.userAgent + new Date()),
          ratings: {},
        };
        window.localStorage.setItem(
          "qelp_latam_dynamic_user",
          JSON.stringify(user)
        );
      } else {
        user = JSON.parse(user);
      }
      this.data.id = user?.id ? user.id : sha256(window.navigator.userAgent + new Date());
      if (user.ratings) this.data.ratings = user.ratings;
      if (user.accessibility) this.data.accessibility = user.accessibility;
      if (user.history) {
        this.data.history = user.history;

        let now = Date.now();
        let twoDaysAgo = now - 2 * 24 * 60 * 60 * 1000;
        let recentHistory = [];

        for (let i = 0; i < this.data.history.length; i++) {
          let element = this.data.history[i];
          let historyDate = new Date(element.date);
          if (historyDate.getTime() >= twoDaysAgo) {
            recentHistory.push(element);
          }
        }

        if (recentHistory.length !== this.data.history.length) {
          this.data.history = recentHistory;
          this.update();
        }
      }
      if (user.searchs) {
        this.data.searchs = user.searchs;
      }
    } catch (error) {
      console.log(error);
    }
  }
  addSearchTerm(data) {
    if (
      !this.data.searchs.find((item) => {
        return item.terms == data.terms;
      })
    ) {
      this.data.searchs.unshift(data);
    }
    while (this.data.searchs.length > 20) {
      this.data.searchs.pop();
    }
  }
  update() {
    window.localStorage.setItem(
      "qelp_latam_dynamic_user",
      JSON.stringify(this.data)
    );
    let event = new Event("UserUpdated");
    document.dispatchEvent(event);
  }
  vote(data = { hashkey: null, rating: null }, callback = null) {
    this.data.ratings[data.hashkey] = data.rating;
    if (callback) callback();
    this.update();
  }
}
